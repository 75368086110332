<template>
  <div class="c-unit-header">
    <div
      v-if="showCover && cover.url"
      class="c-unit__cover"
      :style="[{ 'background-image': `url(${cover.url})` }]"
    />
    <div class="c-unit__header">
      <Breadcrumbs
        class="c-unit__breadcrumbs u-px"
        v-if="showCrumbs && crumbs"
        :crumbs="crumbs"
      />
      <VHeading
        header="h2"
        class="c-unit__unit-title u-px"
        :class="[
          showCrumbs ? 'c-unit__unit-title--right' : 'c-unit__unit-title--left',
        ]"
      >
        <span v-if="showIndex && unitIndex" class="c-unit__unit-title-index">{{
          unitIndex
        }}</span>
        {{ unitName }}
      </VHeading>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'
import { fetchCourseUnits, fetchUnitBySlug } from '@/services/strapi'
import VHeading from '@forlagshuset/v-heading'
import Breadcrumbs from '@forlagshuset/v-breadcrumbs'

export default {
  components: {
    Breadcrumbs,
    VHeading,
  },

  data() {
    return {
      color: [],
    }
  },

  props: {
    unitSlug: {
      type: String,
      default: '',
    },
    element: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    showCover: {
      type: Boolean,
      default: false,
    },
    showCrumbs: {
      type: Boolean,
      default: true,
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const crumbs = ref([])
    const cover = ref({})
    const unitName = ref('')
    const unitIndex = ref(0)
    const y = ref([750, 475, 450, 80, 415])

    onMounted(async () => {
      const unit = await fetchUnitBySlug(props.unitSlug)
      //find index of the unit
      const units = await fetchCourseUnits('echo')
      units.forEach((u, i) => {
        if (u.slug === props.unitSlug) {
          unitIndex.value = i + 1
          unitName.value = u.name

          return
        }
      })

      if (unit.cover) {
        const coverURL = unit.cover.image_type.file.url
        const param =
          'w_1024,c_scale/w_1024,h_114,c_crop,y_' + y.value[unitIndex.value - 1]
        cover.value = {
          url: addCloudinaryUrlParams(coverURL, param),
          altText: unit.cover.image_type.altText,
        }
      }

      //add unit
      crumbs.value.push({
        type: 'search',
        name: unitName.value,
        slug: `?unit=${props.unitSlug}&type=${props.type}`,
      })

      // add lesson
      crumbs.value.push({
        type: '', //we don't need this actually
        name: props.element,
        slug: '', //we don't need this actually too
      })
    })

    return {
      crumbs,
      cover,
      unitName,
      unitIndex,
    }
  },
}
</script>

<style lang="scss">
.c-unit__cover {
  height: 85px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.c-unit__header {
  display: flex;
  align-items: center;
  justify-content: center;
  .c-unit__unit-title {
    font-family: $headers-font-family;
    &--right {
      margin-left: auto;
    }
    &--left {
      margin-right: auto;
    }
    color: $color-white;
    h2 {
      font-size: 2.125rem;
      font-weight: 300;
      margin: 0;
      line-height: 1.25;
    }
  }
}

.c-unit__breadcrumbs {
  color: $color-white;
  .c-breadcrumbs__home {
    text-decoration: none;
    color: inherit;
    .c-breadcrumbs__home {
      fill: none;
      width: 0;
    }
    &:before {
      content: 'Home';
      vertical-align: super;
    }
  }
  .c-breadcrumbs__link {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
