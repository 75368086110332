<template>
  <div v-if="content.audio_type"
    class="c-audio-element">
    <FHAudioPlayer class="c-audio-element__player u-mb"
      type="simple"
      :fileUrl="content.audio_type.file.url"
    />
    
    <div class="c-audio-element__chapters">
      <FHVideoChapter
        v-for="chapter in content.audio_type.mediaChapter"
        :key="chapter.title"
        :title="chapter.title"
        :start="chapter.start"
        :end="chapter.end"
        :currentTime="playerCurrentTime"
        @click.native="updateCurrentTime(chapter.start)"
      />

    <FHButton defaultStyles
      v-if="content.audio_type.audioText"
      @click.native="showTranscript = !showTranscript">{{ $t('VIDEO_TRANSCRIPT')}}</FHButton>
    <div v-show="showTranscript" class="c-audio-element__audio-text" v-md-html="content.audio_type.audioText" />

    </div>
  </div>
</template>

<script>
import FHAudioPlayer from '@forlagshuset/v-audio-player'
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    FHAudioPlayer,
    FHButton
  },

  data () {
    return {
      showTranscript: false
    }
  },

  methods: {
    updateCurrentTime(time) {
      this.$refs.player.player.currentTime(time)
      this.$refs.player.player.play()
    },
  },

  props: {
    content: Object
  },

}
</script>
